import {
  INITIAL_REQUEST,
  FETCH_ASKS_SUCCESS,
  FETCH_ASKS_FAILURE,
  FETCH_TAG_QUESTIONS,
  FETCH_ANSWERED_ASKS_SUCCESS,
  FETCH_ANSWERED_ASKS_FAILURE,
  GET_REPLY_SUCCESS,
  GET_REPLY_FAILURE,
  GET_RESPONDER_STATS_FAILURE,
  GET_RESPONDER_STATS_SUCCESS,
  FETCH_RESPONDERS_SUCCESS,
  FETCH_RESPONDERS_FAILURE,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAILURE,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_FAILURE
} from '../constants/actions-constants';

const initialState = {
  loading: false,
};

const networkReducer = (state = initialState, { type }) => {
  switch (type) {
    case INITIAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ASKS_SUCCESS:
    case FETCH_TAG_QUESTIONS:
    case FETCH_ASKS_FAILURE:
    case FETCH_ANSWERED_ASKS_SUCCESS:
    case FETCH_ANSWERED_ASKS_FAILURE:
    case GET_REPLY_SUCCESS:
    case GET_REPLY_FAILURE:
    case GET_RESPONDER_STATS_FAILURE:
    case GET_RESPONDER_STATS_SUCCESS:
    case CREATE_TAG_SUCCESS:
    case CREATE_TAG_FAILURE:
    case UPDATE_TAG_SUCCESS:
    case UPDATE_TAG_FAILURE:
    case FETCH_RESPONDERS_FAILURE:
    case FETCH_RESPONDERS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
export default networkReducer;
