import {
  PUSH_CONTENT_REQUEST,
  PUSH_CONTENT_SUCCESS,
  PUSH_CONTENT_FAILURE,
} from '../constants/actions-constants';

const inistialState = {
  loading: false,
  response: '',
  error: '',
};

const pushContentReducer = (state = inistialState, action) => {
  switch (action.type) {
    case PUSH_CONTENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PUSH_CONTENT_SUCCESS:
      return {
        ...state,
        response: action.response,
        loading: false,
        error: '',
      };
    case PUSH_CONTENT_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        response: '',
      };

    default:
      return state;
  }
};

export default pushContentReducer;
