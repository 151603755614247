import {
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAILURE,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_FAILURE,
} from '../constants/actions-constants';

const initialState = {
  response: {},
  error: '',
};
const createTagReducer = (state= initialState, { type, payload }) => {
  switch (type) {
    case CREATE_TAG_SUCCESS:
      return {
        ...state,
        response: payload.retuned_resultset,
        error: ''
      };
    case CREATE_TAG_FAILURE:
      return {
        ...state,
        error: payload,
        response: {},
      };
    case UPDATE_TAG_SUCCESS:
      return {
        ...state,
        response: payload
      };
    case UPDATE_TAG_FAILURE:
      return {
        ...state,
        error: payload,
        response: {},
      };

    default:
      return state;
  }
};

export default createTagReducer