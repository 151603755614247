import {
  FETCH_RESPONDER_SUCCESS,
  FETCH_RESPONDER_FAILURE,
} from '../constants/actions-constants';

const initialState = {
  error: '',
  user: {},
};

const responderReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_RESPONDER_SUCCESS:
      return {
        ...state,
        error: '',
        user: payload.returned_resultset,
      };

    case FETCH_RESPONDER_FAILURE:
      return {
        ...state,
        error: payload,
        user: {},
      };

    default:
      return state;
  }
};

export default responderReducer;
