import CryptoJs from 'crypto-js';
import { LOCAL_STORAGE_KEY } from '../redux/constants/actions-constants';

const key = process.env.REACT_APP_ENCRYPTION_KEY || 'this-is-very-secret';

export const encryptKey = userInfo => {
  // Encrypt
  const data = CryptoJs.AES.encrypt(JSON.stringify(userInfo), key).toString();
  // set user info to local storage
  localStorage.setItem(LOCAL_STORAGE_KEY, data);
};

export const decryptKey = () => {
  const cipher = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (cipher) {
    const bytes = CryptoJs.AES.decrypt(cipher, key);
    const userInfo = bytes.toString(CryptoJs.enc.Utf8);
    return JSON.parse(userInfo);
  }
  return {};
};

export const userToken = () => {
  const userInfo = decryptKey();
  if (userInfo !== undefined) {
    return userInfo.token;
  }
  return null;
};

export const loggedInUserRoles = () => {
  let roles = [];
  const userInfo = decryptKey();
  if (userInfo && userInfo && userInfo.roles) {
    roles = [...userInfo.roles];
  }
  return roles;
};

export const isAuthorized = allowedRoles => {
  const userRoles = loggedInUserRoles();
  if (!allowedRoles || allowedRoles.length === 0) return false;
  if (userRoles.length === 0 && !allowedRoles) return false;
  return userRoles.some(role => allowedRoles.includes(role.name));
};

export const isAdmin = () => {
  const userRoles = loggedInUserRoles();
  const allowedRoles = [
    'AAT_RESPONDER_ADMIN',
    'API_ADMIN',
    'ADMIN',
    'AAT_ADMIN',
  ];
  return userRoles.some(role => allowedRoles.includes(role.name));
};
