import React from 'react';
import './styles.scss';
import { routes } from '../../routes';
import {useNavigate} from 'react-router-dom';

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <div className='not-found'>
      <div className="not-found__content">
        <h3 className='not-found__header'>Page Not Found</h3>
        <p>Oops, the page you requested could not be found.</p>
        <p>
          Please <span className='link' onClick={() => navigate(routes.landing)}>click here</span> to return to the
          homepage
        </p>
      </div>
    </div>
  );
}
