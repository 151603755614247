import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { routes } from './routes/index';
import Loader from './components/helpers/Loader';
import NotFound from './components/NotFound';
import AppLayout from './components/Layout/Layout';

const HeaderComponent = lazy(() => import('./components/Header/Header'));
const QuestionContainer = lazy(() =>
  import('./components/Question/containers/asks.container')
);
const AnsweredQuestionContainer = lazy(() =>
  import('./components/Question/containers/answere-question.container')
);
const Login = lazy(() => import('./components/Auth/Login'));
const AuthRouter = lazy(() => import('./components/AuthRouter/index'));
const MyStats = lazy(() => import('./components/Stats'));
const ViewTags = lazy(() => import('./components/Tag/ViewTags'));

const roles = [
  'API_ADMIN',
  'ADMIN',
  'AAT_RESPONDER',
  'AAT_ADMIN',
  'AAT_RESPONDER_ADMIN',
];
function App() {
  return (
    <Suspense fallback={<Loader style={{ height: '100vh' }} />}>
      <BrowserRouter>
        <Routes>
          <Route path={routes.login} element={<Login />} />
          <Route path={routes.landing} element={<AppLayout />}>
            <Route
              index
              element={
                <AuthRouter roles={roles}>
                  <QuestionContainer />
                </AuthRouter>
              }
            />
            <Route
              path={routes.answeredQuestions}
              element={
                <AuthRouter roles={roles}>
                  <AnsweredQuestionContainer />
                </AuthRouter>
              }
            />
            <Route
              path={routes.stats}
              element={
                <AuthRouter roles={roles}>
                  <MyStats />
                </AuthRouter>
              }
            />
            <Route
              path={routes.tags}
              element={
                <AuthRouter roles={roles}>
                  <ViewTags />
                </AuthRouter>
              }
            />
          </Route>
          <Route path="/*" element={<NotFound/>} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
