import { combineReducers } from 'redux';
import networkReducer from './network/network.reducer';
import asksReducer from './asks/asks.reducer';
import answeredAsksReducer from './asks/answered-asks.reducer';
import createReplyReducer from './replies/create-reply.reducer';
import getReplyReducer from './replies/get-reply.reducer';
import askReducer from './asks/ask.reducer';
import fetchLessonsReducer from './content/fetch-lessons.reducer';
import pushContentReducer from './content/push-content.reducer';
import updateReplyReducer from './replies/update-reply.reducer';
import fetchTokenReducer from './auth/auth.reducer';
import statsReducer from './stats/stats.reducer';
import userAsksReducer from './asks/users-questions-history-reducer';
import fetchTagsReducer from './tags/fetch-tags.reducer';
import createTagReducer from './tags/create-tag.reducer';
import respondersReducer from './responders/responders.reducer';
import deleteTagReducer from './tags/delete-tag.reducer';
import responderReducer from './responders/responder.reducer';

const rootReducer = combineReducers({
  network: networkReducer,
  asks: asksReducer,
  answeredAsks: answeredAsksReducer,
  createReply: createReplyReducer,
  updateReply: updateReplyReducer,
  reply: getReplyReducer,
  ask: askReducer,
  lessons: fetchLessonsReducer,
  pushContent: pushContentReducer,
  auth: fetchTokenReducer,
  stats: statsReducer,
  userAsks: userAsksReducer,
  tags: fetchTagsReducer,
  createTag: createTagReducer,
  responders: respondersReducer,
  responder: responderReducer,
  deleteTag: deleteTagReducer
});

export default rootReducer;
