import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';

let store;
const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;
const enhancer = composeEnhancers(applyMiddleware(thunk));
if (process.env.NODE_ENV === 'development') {
  store = createStore(rootReducer, enhancer);
} else {
  store = createStore(rootReducer, applyMiddleware(thunk));
}

export default store;
