import React from 'react';
import { Layout } from 'antd';
import MenuComponent from './Menu';
import './styles/header.styles.scss';

export default function HeaderComponent() {
  const { Header } = Layout;
  return (
    <div>
      <Header className='header'>
        <MenuComponent />
      </Header>
    </div>
  );
}
