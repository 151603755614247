import {
  FETCH_ASK_SUCCESS,
  FETCH_ASK_FAILURE,
  FETCH_ASK_REQUEST,
} from '../constants/actions-constants';

const initialState = {
  response: {},
  error: '',
  loading: false,
};

const askReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ASK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ASK_SUCCESS:
      return {
        ...state,
        loading: false,
        response: payload.returned_resultset,
        error: ""
      };
    case FETCH_ASK_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error,
        response: {}
      };

    default:
      return state;
  }
};

export default askReducer;
