import {
  GET_REPLY_SUCCESS,
  GET_REPLY_FAILURE,
} from '../constants/actions-constants';

const initialState = {
  reply: '',
  error: '',
};

const getReplyReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_REPLY_SUCCESS:
      return {
        ...state,
        reply: payload.data,
      };

    case GET_REPLY_FAILURE:
      return {
        ...state,
        error: payload,
      };

    default:
      return state;
  }
};

export default getReplyReducer;
