import {
  FETCH_RESPONDERS_SUCCESS,
  FETCH_RESPONDERS_FAILURE,
} from '../constants/actions-constants';

const initialState = {
  error: '',
  response: {},
  responders: [],
};

const respondersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_RESPONDERS_SUCCESS:
      return {
        ...state,
        error: '',
        response: payload,
        responders: payload.returned_resultset,
      };

    case FETCH_RESPONDERS_FAILURE:
      return {
        ...state,
        error: payload,
        response: {},
        responders: [],
      };

    default:
      return state;
  }
};

export default respondersReducer;
