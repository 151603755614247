import {
  GET_RESPONDER_STATS_SUCCESS,
  GET_RESPONDER_STATS_FAILURE,
} from '../constants/actions-constants';

const initialState = {
  data: [],
  response: {},
  error: '',
};

const statsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_RESPONDER_STATS_SUCCESS:
      return {
        ...state,
        response: payload,
        data: payload.responder_data,
        error: '',
      };

    case GET_RESPONDER_STATS_FAILURE:
      return {
        ...state,
        data: [],
        response: {},
        error: payload,
      };

    default:
      return state;
  }
};

export default statsReducer;
