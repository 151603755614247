/** inital Request */
export const INITIAL_REQUEST = 'INITIAL_REQUEST';

/** Asks actions */
export const FETCH_ASKS_SUCCESS = 'FETCH_ASKS_SUCCESS';
export const FETCH_ASKS_FAILURE = 'FETCH_ASKS_FAILURE';

/** Ask actions */
export const FETCH_ASK_REQUEST = 'FETCH_ASK_REQUEST';
export const FETCH_ASK_SUCCESS = 'FETCH_ASK_SUCCESS';
export const FETCH_ASK_FAILURE = 'FETCH_ASK_FAILURE';

/** Asks actions */
export const FETCH_MORE_ASKS_REQUEST = 'FETCH_MORE_ASKS_REQUEST';
export const FETCH_MORE_ASKS_SUCCESS = 'FETCH_MORE_ASKS_SUCCESS';
export const FETCH_MORE_ASKS_FAILURE = 'FETCH_MORE_ASKS_FAILURE';

/** Asks actions */
export const FETCH_ANSWERED_ASKS_SUCCESS = 'FETCH_ANSWERED_ASKS_SUCCESS';
export const FETCH_ANSWERED_ASKS_FAILURE = 'FETCH_ANSWERED_ASKS_FAILURE';

/** Lessons actions */
export const FETCH_LESSONS_REQUEST = 'FETCH_LESSONS_REQUEST';
export const FETCH_LESSONS_SUCCESS = 'FETCH_LESSONS_SUCCESS';
export const FETCH_LESSONS_FAILURE = 'FETCH_LESSONS_FAILURE';
export const RESET_LESSONS = 'RESET_LESSONS';

/** Push content actions */
export const PUSH_CONTENT_REQUEST = 'PUSH_CONTENT_REQUEST';
export const PUSH_CONTENT_SUCCESS = 'PUSH_CONTENT_SUCCESS';
export const PUSH_CONTENT_FAILURE = 'PUSH_CONTENT_FAILURE';

/** Replies actions */
export const CREATE_REPLY_REQUEST = 'CREATE_REPLY_REQUEST';
export const CREATE_REPLY_SUCCESS = 'CREATE_REPLY_SUCCESS';
export const CREATE_REPLY_FAILURE = 'CREATE_REPLY_FAILURE';

export const UPDATE_REPLY_REQUEST = 'UPDATE_REPLY_REQUEST';
export const UPDATE_REPLY_SUCCESS = 'UPDATE_REPLY_SUCCESS';
export const UPDATE_REPLY_FAILURE = 'UPDATE_REPLY_FAILURE';

export const GET_REPLY_SUCCESS = 'GET_REPLY_SUCCESS';
export const GET_REPLY_FAILURE = 'GET_REPLY_FAILURE';

// asks token actions
export const GET_TOKEN_REQUEST = 'GET_TOKEN_REQUEST';
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const GET_TOKEN_FAILURE = 'GET_TOKEN_FAILURE';

// asks token actions
export const GET_RESPONDER_STATS_SUCCESS = 'GET_RESPONDER_STATS_SUCCESS';
export const GET_RESPONDER_STATS_FAILURE = 'GET_RESPONDER_STATS_FAILURE';

/** Responders actions */
export const FETCH_RESPONDERS_SUCCESS = 'FETCH_RESPONDERS_SUCCESS';
export const FETCH_RESPONDERS_FAILURE = 'FETCH_RESPONDERS_FAILURE';

/** Responders actions */
export const FETCH_RESPONDER_SUCCESS = 'FETCH_RESPONDER_SUCCESS';
export const FETCH_RESPONDER_FAILURE = 'FETCH_RESPONDER_FAILURE';

/** Responders actions */
export const FETCH_USER_ASKS_REQUEST = 'FETCH_USER_ASKS_REQUEST';
export const FETCH_USER_ASKS_SUCCESS = 'FETCH_USER_ASKS_SUCCESS';
export const FETCH_USER_ASKS_FAILURE = 'FETCH_USER_ASKS_FAILURE';

export const FILTER_ANSWERED_QUESTIONS = 'FILTER_ANSWERED_QUESTIONS';
export const GET_TAGGED_QUESTION = 'GET_TAGGED_QUESTIONS';

export const FETCH_TAG_QUESTIONS = 'FETCH_TAG_QUESTIONS';

export const GET_PUSHER_QUESTIONS = 'GET_PUSHER_QUESTIONS';


export const FETCH_TAGS_REQUEST = 'FETCH_TAGS_REQUEST';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_FAILURE = 'FETCH_TAGS_FAILURE';
export const FETCH_TAGS_BY_NAME = 'FETCH_TAGS_BY_NAME';
export const RESET_TAGS = 'RESET_TAGS';
export const FETCH_TAG_QUESTIONS_SUCCESS = 'FETCH_TAG_QUESTIONS_SUCCESS'
export const FETCH_TAG_QUESTIONS_FAILURE = 'FETCH_TAG_QUESTIONS_FAILURE'

export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS';
export const CREATE_TAG_FAILURE = 'CREATE_TAG_FAILURE';

export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS';
export const UPDATE_TAG_FAILURE = 'UPDATE_TAG_FAILURE';

export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
export const DELETE_TAG_FAILURE = 'DELETE_TAG_FAILURE';

export const GET_EXPORT_DATA_REQUEST = 'GET_EXPORT_DATA_REQUEST';
export const GET_EXPORT_DATA_SUCCESS = 'GET_EXPORT_DATA_SUCCESS';
export const GET_EXPORT_DATA_FAILURE = 'GET_EXPORT_DATA_FAILURE';

export const NETWORK_ERROR = {
  message: 'Something went wrong, please try again later',
};

export const LOCAL_STORAGE_KEY = 'eneze-aat-fronted-key';
