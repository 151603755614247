import {
  FETCH_USER_ASKS_SUCCESS,
  FETCH_USER_ASKS_FAILURE,
  FETCH_USER_ASKS_REQUEST,
} from '../constants/actions-constants';

const initialState = {
  data: [],
  error: '',
  loading: false,
};

const userAsksReducer = (state = initialState, { type, payload }) => {
  switch (type) {

    case FETCH_USER_ASKS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_ASKS_SUCCESS:
      return {
        ...state,
        data: [...payload.returned_resultset],
        loading: false,
      };
    case FETCH_USER_ASKS_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
        data: []
      };

    default:
      return state;
  }
};

export default userAsksReducer;
