import {
  CREATE_REPLY_SUCCESS,
  CREATE_REPLY_FAILURE,
  CREATE_REPLY_REQUEST,
} from '../constants/actions-constants';

const initialState = {
  loading: false,
  response: '',
  error: '',
};

const createReplyReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_REPLY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_REPLY_SUCCESS:
      return {
        ...state,
        loading: false,
        response: payload.data,
      };

    case CREATE_REPLY_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default createReplyReducer;
