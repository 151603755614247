import React from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import HeaderComponent from '../Header/Header';

const AppLayout = () => {
  const { Content } = Layout;
  return (
    <>
      <HeaderComponent />
      <Layout>
        <div className='main-content'>
          <Content className='content-container'>
            <Outlet />
          </Content>
        </div>
      </Layout>
    </>
  );
};

export default AppLayout;
