import {
  FETCH_ANSWERED_ASKS_SUCCESS,
  FETCH_ANSWERED_ASKS_FAILURE,
  GET_EXPORT_DATA_FAILURE,
  GET_EXPORT_DATA_SUCCESS,
  GET_EXPORT_DATA_REQUEST
} from '../constants/actions-constants';

const initialState = {
  response: {},
  responses: [],
  data: [],
  error: '',
  processing: false,
};

const answeredAsksReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ANSWERED_ASKS_SUCCESS:
      return {
        ...state,
        response: payload,
        data: [...payload.returned_resultset],
      };
    case FETCH_ANSWERED_ASKS_FAILURE:
      return {
        ...state,
        error: payload.error,
        data: [],
      };
    case GET_EXPORT_DATA_REQUEST:
      return {
        ...state,
        processing: true,
      }
    case GET_EXPORT_DATA_SUCCESS:
      return {
        ...state,
        processing: false,
        responses: payload
      }
    case GET_EXPORT_DATA_FAILURE:
      return {
        ...state,
        processing: false,
        responses: [],
      }

    default:
      return state;
  }
};

export default answeredAsksReducer;
