import {
  FETCH_ASKS_SUCCESS,
  FETCH_ASKS_FAILURE,
  FETCH_MORE_ASKS_SUCCESS,
  FETCH_MORE_ASKS_FAILURE,
  FETCH_MORE_ASKS_REQUEST,
  FILTER_ANSWERED_QUESTIONS,
  GET_PUSHER_QUESTIONS,
  FETCH_TAG_QUESTIONS,
  GET_TAGGED_QUESTION,
} from '../constants/actions-constants';

const initialState = {
  response: '',
  error: '',
  data: [],
  loading: false,
};

const asksReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case FETCH_ASKS_SUCCESS:
      return {
        ...state,
        response: payload,
        error: '',
        data: [...payload.returned_resultset],
      };
    case FETCH_MORE_ASKS_REQUEST:
      return {
        ...state,
        loding: true,
        error: '',
      };
    case FETCH_MORE_ASKS_SUCCESS:
      const { data } = state;
      return {
        ...state,
        response: payload,
        error: '',
        loading: false,
        data: [...data, ...payload.returned_resultset],
      };
    case FETCH_ASKS_FAILURE:
      return {
        ...state,
        response: '',
        error: payload,
        loading: false,
        data: [],
      };
    case FETCH_MORE_ASKS_FAILURE:
      return {
        ...state,
        response: '',
        loading: false,
        error: payload,
        data: [],
      };
    case GET_PUSHER_QUESTIONS:
      return {
        ...state,
        data: [...payload, ...state.data],
      };

    case FILTER_ANSWERED_QUESTIONS:
      const filteredData = state?.data?.filter(ask => ask?.id !== payload?.id);
      return {
        ...state,
        data: filteredData,
      };

    case GET_TAGGED_QUESTION:
      const { id, tag } = payload;
      const asks = state.data.map(question => {
        if (question?.id === id) {
          question.tags = [...questions?.tags, tag];
        }
        return question;
      });

      return {
        ...state,
        data: asks,
      };

    case FETCH_TAG_QUESTIONS:
      let error = '';
      let questions = payload;
      if (questions.length === 0) {
        error = {
          message: 'There are no questions for the selected tag',
        };
        questions = [];
      }
      return {
        ...state,
        data: questions,
        error,
        loading: false,
      };

    default:
      return state;
  }
};

export default asksReducer;
