import React from 'react';
import { Spin } from 'antd';

export default function Loader(props) {
  return (
    <div className='spinner' {...props}>
      <Spin />
    </div>
  );
}
