import {
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILURE,
} from '../constants/actions-constants';

const initialState = {
  error: '',
  response: {},
};

const deleteTagReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case DELETE_TAG_SUCCESS:
      return {
        ...state,
        error: '',
        response: payload,
      };

    case DELETE_TAG_FAILURE:
      return {
        ...state,
        error: payload,
        response: {},
      };

    default:
      return state;
  }
};

export default deleteTagReducer;