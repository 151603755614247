import {
  FETCH_TAGS_REQUEST,
  FETCH_TAGS_FAILURE,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_BY_NAME,
  RESET_TAGS,
} from '../constants/actions-constants';

const initialState = {
  loading: false,
  tags: [],
  allTags: [],
  response: {},
  error: '',
};
const fetchTagsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_TAGS_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case FETCH_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        response: payload,
        error: '',
        tags: payload.returned_resultset,
        
      };
    case FETCH_TAGS_BY_NAME:
      return {
        ...state,
        loading: false,
        error: '',
        allTags: payload.returned_resultset,
        
      };
    case FETCH_TAGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
        tags: [],
        allTags: []
      };
      
    case RESET_TAGS:
      return {
        ...state,
        allTags: []
      };


    default:
      return state;
  }
};

export default fetchTagsReducer;
