import React, { useState } from 'react';
import { Menu, Popover } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { NavLink} from 'react-router-dom';
import { routes } from '../../routes/index';
import { logout } from '../../redux/auth/logout.actions';
import { decryptKey } from '../../utils/authHelpers';

function MenuComponent() {
  const [visible, setVisible] = useState(false);
  const activeStyle = {
    color: '#1890ff',
  };

  const handleLogout = () => {
    setVisible(false);
    logout();
  };
  const user = decryptKey();
  return (
    <div className='menu-container'>
      <>
        <div className='logo' />
        <Menu mode='horizontal' className='menu'>
          <Menu.Item key='asks'>
            <NavLink activeStyle={activeStyle} to={routes.landing} exact>
              Questions
            </NavLink>
          </Menu.Item>
          <Menu.Item key='answered'>
            <NavLink activeStyle={activeStyle} to={routes.answeredQuestions}>
              Answered Questions
            </NavLink>
          </Menu.Item>
          <Menu.Item key='stats'>
            <NavLink activeStyle={activeStyle} to={routes.stats}>
              Statistics
            </NavLink>
          </Menu.Item>
          <Menu.Item key='tags'>
            <NavLink activeStyle={activeStyle} to={routes.tags}>
              Tags
            </NavLink>
          </Menu.Item>
        </Menu>
      </>
      <Popover
        content={
          <p onClick={handleLogout} style={{ cursor: 'pointer' }}>
            Log out
          </p>
        }
        placement='bottom'
        trigger='click'
        visible={visible}
        onVisibleChange={vis => setVisible(vis)}
        className='user-account'
      >
        <div
          role='button'
          style={{ cursor: 'pointer' }}
          onClick={() => setVisible(true)}
        >
          <UserOutlined className='user-account__icon' />
          <span className='user-account__name'>{user && user.username}</span>
        </div>
      </Popover>
    </div>
  );
}

export default MenuComponent;
